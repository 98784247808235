<template>
  <div
    class="
      upload-apply-store-photo
      sign-in
      d-flex
      flex-column
      align-center
      mx-4
      pt-16
    "
  >
    <div class="text-h4 mb-8">
      {{ $t("__upload_apply_store_photo_title") }}
    </div>
    <v-stepper class="no-shadow">
      <v-stepper-header>
        <v-stepper-step step="1" complete>
          {{ $t("__fill_info_form") }}
        </v-stepper-step>
        <v-divider class="progress-divider hidden-sm-and-down"></v-divider>
        <v-stepper-step class="v-stepper__step--active" step="2">
          {{ $t("__upload_photo") }}
        </v-stepper-step>
        <v-divider class="progress-divider hidden-sm-and-down"></v-divider>
        <v-stepper-step step="3"> {{ $t("__completed") }} </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-card class="ma-2" elevation="0" width="100%" v-if="form">
      <v-card-text class="pa-6">
        <div
          class="d-flex flex-column justify-center"
          v-for="photoField of photoFields"
          :key="photoField.name"
        >
          <v-card
            class="ma-4"
            v-if="
              photoField.storeOwnerTypes.findIndex(
                t => t === form['store_owner_type']
              ) !== -1
            "
          >
            <v-card-title class="justify-center">
              <div>{{ $t(`__${photoField.name}_photo`) }}</div>
            </v-card-title>
            <v-divider />
            <v-row justify="center" v-if="photoField.samples">
              <v-col
                :cols="isMobile ? 6 : 4"
                v-for="sample in photoField.samples"
                :key="sample"
              >
                <v-img :src="sample"></v-img>
              </v-col>
            </v-row>
            <div class="d-flex flex-wrap justify-center">
              <v-card
                class="d-flex justify-center align-center ma-2"
                v-for="photoIndex in photoField.quantity"
                :key="`${photoField.name}-photo_${photoIndex - 1}`"
                outlined
                width="360"
                height="270"
              >
                <template v-if="!photoField.photoURLs[photoIndex - 1]">
                  <v-icon
                    size="64px"
                    color="grey lighten-2"
                    @click="toSelectPhoto(photoField.name, photoIndex - 1)"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
                <template v-else>
                  <v-img
                    contain
                    width="360"
                    height="270"
                    :src="photoField.photoURLs[photoIndex - 1]"
                  />
                  <v-btn
                    class="mb-8"
                    fab
                    small
                    color="primary"
                    absolute
                    bottom
                    right
                    @click="toSelectPhoto(photoField.name, photoIndex - 1)"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-card>
            </div>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <input
      type="file"
      style="display: none"
      ref="imageInput"
      @change="selectedPhoto"
      accept="image/*"
    />
    <v-dialog v-model="imageCropperShow" persistent max-width="1000px">
      <image-cropper
        :image="imageCropperImage"
        @confirm="imageCropperConfirm"
        @cancel="imageCropperCancel"
      />
    </v-dialog>
    <v-btn
      class="mb-8"
      x-large
      color="success"
      fixed
      bottom
      right
      :disabled="!photosIsUploaded"
      @click="toSubmitForm"
    >
      {{ $t("__submit_create_store_application_form") }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UploadApplyStorePhoto",
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      form: "createStoreApplicationForm/createStoreApplicationForm"
    })
  },
  data() {
    return {
      photoFields: [
        {
          name: "bank_passbook",
          quantity: 1,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "store_sign",
          quantity: 3,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"],
          samples: [
            require("@/assets/photoSample/store_gate.jpg"),
            require("@/assets/photoSample/storehouse.jpg"),
            require("@/assets/photoSample/signboard.jpg")
          ]
        },
        {
          name: "environment",
          quantity: 2,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"],
          samples: [
            require("@/assets/photoSample/doorplate.jpg"),
            require("@/assets/photoSample/store_enviroment.jpg")
          ]
        },
        {
          name: "registration_certificate",
          quantity: 2,
          photoURLs: [],
          storeOwnerTypes: ["company"]
        },
        {
          name: "owner_id_card",
          quantity: 2,
          photoURLs: [],
          storeOwnerTypes: ["company", "personal"],
          samples: [
            require("@/assets/photoSample/id_front.jpg"),
            require("@/assets/photoSample/id_back.jpg")
          ]
        }
      ],
      selectedPhotoName: "",
      selectedPhotoIndex: "",
      imageCropperShow: false,
      imageCropperImage: "",
      photosIsUploaded: false
    };
  },
  methods: {
    loadCreateStoreApplicationForm() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch(
          "createStoreApplicationForm/fetchCreateStoreApplicationForm",
          {
            createStoreApplicationFormID: this.$route.params.id
          }
        )
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.replace({ name: "CreateStoreApplicationForms" });
        });
    },
    toSelectPhoto(name, index) {
      this.selectedPhotoName = name;
      this.selectedPhotoIndex = index;
      this.$refs.imageInput.click();
    },
    selectedPhoto(event) {
      if (event.target.files && event.target.files[0]) {
        this.$store.dispatch("setIsLoading", true);
        const reader = new FileReader();
        reader.onload = e => {
          this.$store.dispatch("setIsLoading", false);
          if (typeof e.target?.result == "string") {
            this.imageCropperImage = e.target.result;
            this.imageCropperShow = true;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      event.target.value = "";
    },
    imageCropperConfirm(imageDataURL) {
      this.imageCropperShow = false;
      this.uploadPhoto(
        this.selectedPhotoName,
        this.selectedPhotoIndex,
        "png",
        imageDataURL
      );
    },
    imageCropperCancel() {
      this.imageCropperShow = false;
    },
    uploadPhoto(name, index, extension, dataURL) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("createStoreApplicationForm/uploadPhoto", {
          photoName: name,
          photoIndex: index,
          photoExtension: extension,
          photoDataURL: dataURL
        })
        .then(() => {
          this.loadCreateStoreApplicationForm();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.error("err", err);
          alert(err.message);
        });
    },
    checkPhotosIsUploaded(form) {
      let isUploaded = true;
      if (!!form && !!form["photo_urls"]) {
        isUploaded =
          isUploaded &&
          !!form["photo_urls"]["bank_passbook"] &&
          form["photo_urls"]["bank_passbook"].length >= 1 &&
          !!form["photo_urls"]["store_sign"] &&
          form["photo_urls"]["store_sign"].length >= 3 &&
          !!form["photo_urls"]["environment"] &&
          form["photo_urls"]["environment"].length >= 2;
        if (form["store_owner_type"] === "company") {
          isUploaded =
            isUploaded &&
            !!form["photo_urls"]["registration_certificate"] &&
            form["photo_urls"]["registration_certificate"].length >= 2 &&
            !!form["photo_urls"]["owner_id_card"] &&
            form["photo_urls"]["owner_id_card"].length >= 2;
        } else if (form["store_owner_type"] === "personal") {
          isUploaded =
            isUploaded &&
            !!form["photo_urls"]["owner_id_card"] &&
            form["photo_urls"]["owner_id_card"].length >= 2;
        } else {
          isUploaded = false;
        }
      } else {
        isUploaded = false;
      }
      return isUploaded;
    },
    toSubmitForm() {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("createStoreApplicationForm/submitCreateStoreApplicationForm")
        .then(() => {
          this.$router.replace({
            name: "ApplySuccessPage",
            params: this.$route.params.id
          });
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.error("err", err);
          alert(err.message);
        });
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(val) {
        // if form status not unsubmitted and not rejected go back
        if (
          !!val &&
          val["status"] !== "unsubmitted" &&
          val["status"] !== "rejected"
        ) {
          this.$router.replace({ name: "CreateStoreApplicationForms" });
        }
        // photo urls
        for (const i in this.photoFields) {
          const photoName = this.photoFields[i].name;
          this.photoFields[i].photoURLs = [];
          if (
            !!val &&
            !!val["photo_urls"] &&
            !!val["photo_urls"][photoName] &&
            val["photo_urls"][photoName].length > 0
          ) {
            for (const photoURL of val["photo_urls"][photoName]) {
              this.photoFields[i].photoURLs.push(photoURL);
            }
          }
        }
        this.photosIsUploaded = this.checkPhotosIsUploaded(val);
      }
    }
  },
  created() {
    this.loadCreateStoreApplicationForm();
  }
};
</script>
<style lang="scss" scope>
.v-stepper.no-shadow {
  box-shadow: none;
}
.v-divider.progress-divider {
  border-width: thin 130px 0 0;
}
.v-stepper__step--active > .v-stepper__step__step {
  background-color: #0071bc;
  border-color: #0071bc;
}
</style>
